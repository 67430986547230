html {
	overflow-x: hidden;
	&.qwpage {
		overflow: hidden;
	}
}

body {
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
	}
	&[class*="information-"] img {
		max-width: 100%;
	}
	&[class*="page-category"] {
		.main-columns {
			& > .row {
				margin: 0;
			}
		}
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
	}
}

h1 {
	font-weight: 500;
	text-align: center;
	margin: 0;
}

.page-category, .page-special, .page-search, .page-allproduct {
	#page {
		background: $lg;
	}
}

#page {
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 560px);
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

#sidebar-main {
	padding: 0;
}

.common-home {
	#sidebar-main {
		background: transparent;
	}
	#content {
		padding: 0 15px !important;
	}
}

.fancybox-skin {
	background: $wh;
}

header {
	background: $wh;
}

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	height: 40px;
	background-color: $bk;
	color: $wh;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {

		}
	}
	.topcontacts {
		display: flex;
		align-self: center;
		justify-content: space-between;
		svg {
			fill: none;
			stroke: $gb;
			stroke-width: 1.5px;
			width: 18px;
			height: 18px;
			margin-right: 10px;
		}
		.media-body {
			width: auto;
			height: 40px;
			display: flex;
			align-items: center;
			color: $gb;
			a {
				color: $gb !important;
			}
		}
	}
}

.bigcat .col-lg-6 {
	padding: 0 !important;
}

.header-bottom {
	position: relative;
	& > .container {
		display: flex;
		position: relative;
	}
	.logo-container {
		flex: 1 1 auto;
		text-align: center;
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	#searchblock {
		visibility: hidden;
		position: absolute;
		width: 400px;
		height: 85px;
		@include transition(transform .2s ease-in-out);
		transform: scale(0, 1);
		transform-origin: right;
		z-index: 999;
		right: 14px;
		left: auto;
		top: -29px;
		background: $wh;
		form {
			height: 100%;
		}
		&.active {
			visibility: visible;
			transform: scale(1, 1);
		}
	}
	#searchtop {
		display: inline-block;
		@include rounded-corners(20px);
		background: $wh;
		width: 100%;
		top: 24px;
		padding-right: 75px;
		input.form-control {
			background: transparent;
			border-radius: 5px;
			border: 1px $lg solid;
			color: $bk;
			font-style: normal;
			@include placeholder($mg);
		}
		.btn-search {
			height: 35px;
			position: absolute;
			top: 0;
			right: 0;
			text-transform: none;
			font-size: 16px;
			line-height: 1;
		}
	}
	.header-panel {
		@include flex();
		justify-content: right;
		align-items: center;
		position: absolute;
		right: 0;
		height: 36px;
		top: calc(50% - 13px);
		svg {
			width: 28px;
			height: 28px;
		}
	}
	.headercart {
		width: 30px;
		position: relative;
		.cart-total {
			top: auto;
			right: auto;
			left: 50%;
			bottom: -17px;
			transform: translateX(-50%);
		}
		#cart {
			svg {
				path {
					stroke: $bk;
					fill: none;
					stroke-width: 1.5px;
				}
				circle {
					fill: $bk;
					stroke: none;
				}
			}
			&.open .dropdown-toggle {
				box-shadow: none;
			}
			a {
				display: inline-block;
				position: relative;
			}
			.icon-cart {
				background: transparent;
				@include size(55px, 47px);
				display: inline-block;
				margin-right: 10px;
				position: relative;
				text-align: center;
				i {
					font-size: 30px;
					padding: 8px;
				}
			}
			.wrap-cart {
				display: inline-block;
				color: $black;
				.cart-total {
					font-weight: bold;
					background: transparent;
					color: $dg;
					margin-left: 5px;
				}
			}
		}
	}
	.headersearch {
		cursor: pointer;
		svg {
			width: 28px;
			position: relative;
			top: -2px;
			path {
				stroke: $bk;
				fill: none;
				stroke-width: 2.5px;
			}
		}
	}
	.product-compare-header {
		text-align: center;
		width: 28px;
		position: relative;
		svg {
			fill: $bk;
		}
		&:hover {
			svg {
				fill: $bc;
			}
		}
		p {
			margin: 0;
			&#compare-total {
				font-weight: bold;
				line-height: 14px;
				font-size: 12px;
			}
		}
		i {
			font-size: 28px;
		}
	}
	.badge {
		color: $wh;
		position: absolute !important;
		top: -3px;
		right: -7px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $tc;
	}
	.product-compare-header, .headercart, .headersearch {
		margin: 0 18px;
	}
}

.header-menu {
	border-top: 1px $bc solid;
	& > .container {
		position: relative;
	}
}

#partnerpricemodule {
	text-align: center;
	padding: 40px 30px;
	color: $wh;
	background: $new;
	h3 {
		color: $wh;
		text-transform: uppercase;
		font-size: 48px;
		font-weight: 900;
	}
	p {
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 18px;
	}
	button {
		padding: 0 50px;
		color: $wh;
		background: $new;
		font-weight: 500;
		font-size: 18px;
		border: 2px solid $wh;
		box-shadow: 0 20px 46px rgba(0, 0, 0, .3);
		&:hover {
			background: $wh;
			color: $new;
			box-shadow: 0 20px 46px rgba(0, 0, 0, .05);
		}
	}
}

.search_ctrl {
	padding: 0 15px;
}

.compare_rm_all_top {
	position: absolute;
	right: 15px;
	top: 15px;
}

.compare_rm_all_bottom {
	margin: -15px 15px 15px -15px;
	text-align: right;
	display: inline-block;
	width: 100%;
	@media (max-width: 767px) {
		margin-top: 15px;
	}
}

.shopping-cart-table {
	background: $wh;
	font-size: 16px;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			a:hover {
				color: $special;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				color: $special;
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid #ddd;
	}
	&.table {
		thead tr td {
			text-align: center !important;
		}
		tr td {
			border: 1px #ddd solid;
			&.column-price, &.column-total {
				white-space: nowrap;
			}
		}
	}
	.column-quantity {
		min-width: 75px;
		.btn-block {
			max-width: 100px;
			margin: 0 auto;
			text-align: center;
			span {
				display: inline-block;
			}
		}
		input {
			margin-bottom: 10px;
			color: $mg;
			font-style: normal;
			font-size: 22px;
			border-radius: 5px;
			text-align: center;
		}
		.btn {
			height: 28px;
			padding: 0;
			width: 40px;
			&.btn-primary {
				svg {
					fill: $wh;
				}
			}
			.btn-default {
				svg {
					fill: $tc;
				}
			}
		}
		svg {
			width: 30px;
			height: 28px;
			cursor: pointer;
			fill: $tc;
			@include fade();
		}
	}
}

table.total-item {
	font-size: 18px;
	float: right;
	margin-top: 10px;
	background: white;
	tr td:last-child {
		width: 125px;
	}
}

.total-item, .shopping-cart-table {
	tr td {
		vertical-align: middle !important;
		padding: 10px !important;
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	@include flex();
	align-items: center;
	margin: 14px 0;
	width: 100%;
	a {
		display: inline-block;
		margin: 0 auto;
		text-align: center;
		&:hover {
			color: $bk;
		}
	}
	img {
		max-height: 60px;
	}
	span {
		display: block;
		white-space: nowrap;
		text-align: center;
		position: relative;
		top: 5px;
		height: 23px;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 30px;
			&.ft-mail {
				a {
					text-transform: lowercase;
				}
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $wh;
	padding: 45px 0;
	border-top: 2px $lg solid;
	color: $bk;
	font-size: 16px;
	.box {
		.box-heading {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 5px;
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
		span {
			white-space: nowrap;
		}
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
		margin-left: 100px;
		.box {
			margin-right: 30px;
			font-weight: 500;
		}
		.box-heading {
			font-weight: 800;
		}
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $bk;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.oferta p {
			color: $wh;
			font-size: 13px;
			margin-bottom: 3px;
		}
	}
	.payment-img {
		text-align: right;

		img {
			max-height: 61px;
		}
	}
}
.payment2row {
	display: none;
	margin: 8px auto;
}