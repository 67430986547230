.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-family: $product-price-font-family;
	font-weight: bold;
	.price-new {
		color: $black;
		font-size: 24px;
	}
	.price-old {
		text-decoration: line-through;
		color: #666;
	}
	&.detail {
		.price-new {
			font-size: 40px;
		}
		.price-old {
			font-size: 26px;
		}
		ul {
			margin: 0;
		}
	}
}

#tab-gifts {
	h3 {
		margin: 0 0 25px 0;
	}
	ul {
		padding: 40px 0 0;
		list-style: none;
		width: 50%;
		margin: 0 auto;
		li {
			&:after {
				content: '\0418\041B\0418';
				display: block;
				text-align: center;
				font-size: 18px;
				color: $black;
				margin: 15px 0;
			}
			&:last-child:after {
				content: none;
			}
		}
		a {
			display: flex;
			align-items: center;
		}
		div {
			display: inline-block;
			img {
				width: 120px;
			}
			&.gift_name {
				font-size: 22px;
				color: $black;
				margin-left: 25px;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	p {
		margin: 20px 0;
		color: $black;
		text-align: center;
	}
	@media(max-width: 520px) {
		ul {
			li:after {
				text-align: center;
			}
			a {
				flex-wrap: wrap;
			}
		}
		div {
			width: 100%;
		}
		.gift_name {
			text-align: center;
		}
		.gift_image {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}

.product-set {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;

	.set-item {
		position: relative;

		.name {
			font-size: 18px;
			font-weight: 500;
			padding-bottom: 10px;
			text-align: center;
		}
		.price {
			font-weight: 600;
			font-size: 26px;
			margin-bottom: 25px;
			margin-top: 20px;
			text-align: center;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				height: 1px;
				background: #ccd4dc;
				top: -20px;
				left: 30px;
				right: 30px;
			}
		}
		.set-sep {
			display: block;
			position: absolute;
			top: 35%;
			right: -12px;
			font-size: 36px;
			font-weight: bold;
			line-height: 43px;
		}
	}
	.btn-group {
		.prices {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			padding-left: 30px;

			.old-price {
				font-size: 18px;
				line-height: 22px;
				position: relative;
				padding-bottom: 6px;
				width: max-content;

				&:after {
					content: "";
					border-bottom: 1px solid red;
					position: absolute;
					top: 0;
					left: -5px;
					width: 110%;
					height: 50%;
					z-index: 1;
					transform: rotate(-10deg);
				}
			}
			.new-price {
				font-size: 36px;
				line-height: 43px;
				font-weight: bold;
				padding-bottom: 40px;
			}
		}
		.btn {
			white-space: normal;
		}
	}

}

.table-description {
	margin-left: 40px;
	td {
		border: 1px solid #999;
		padding: 2px 5px;
	}
}

.product-label {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	position: absolute;
	z-index: 30;
	top: 10px;
	line-height: 17px;
	padding: 5px 15px;
	color: $wh;
	border-radius: 4px;
	&.sale {
		right: 10px;
		background-color: $special;
	}
	&.new {
		left: 10px;
		background-color: $new;
	}
	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
	&.markdown-product {
		background-color: $special;
		left: 10px;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info {
	.product-info-bg {
		@include flex();
		align-items: stretch;
		flex-wrap: wrap;
		padding: 0;
	}
	.box-product-infomation {
		margin-top: 20px;
	}
	.image-container {
		padding: 0 0 30px 0;
	}
	.title-product {
		font-size: 36px;
		margin: 0;
		font-weight: 300;
		text-align: center;
		padding: 60px 0 30px 0;
		width: 100%;
		b {
			font-weight: 800;
			text-transform: uppercase;
		}
	}
	.row {
		padding: 0 15px;

		.product-row {
			@include flex();
			gap: 20px;

			.product-buttons {
				display: flex;
				gap: 16px;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
			}
		}
		hr {
			border-top: 2px solid $bc;
		}
	}
	.prod_rating {
		.custom-color {
			cursor: pointer;
		}
	}
	.product-price {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.quantity-row {
		display: flex;
		gap: 16px;
	}
	.pricerow {
		.price {
			font-weight: 300;

			&-old {
				font-size: 22px;
				line-height: 1;
				position: relative;
				left: 5px;
				color: $bk;
				opacity: .5;
			}
			&-new {
				color: $bk;
				font-size: 38px;
				font-weight: 500;
				line-height: 1;
			}
		}
	}
	.btn-compare {
		padding: 0;
		@include size(80px, 40px);

		&:hover {
			svg {
				fill: $bk;
			}
		}
		svg {
			@include fade();
			fill: $tc;
			@include size(28px,28px);
			margin: 4px 0;
		}
	}
	.cheaprow {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		flex-direction: column;

		a, button, span {
			font-size: 18px;
			color: $bk;
			cursor: pointer;
			text-decoration: underline $mg;
		}
		&:hover {
			color: $bk;
		}
		svg {
			margin-right: 5px;
			fill: none;
			stroke: $bk;
			@include size(24px,24px);
		}
	}
	.credit {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.attributes {
		margin: 0;

		li {
			font-size: 18px;
			line-height: 28px;
			font-weight: 300;
			color: $dg;
			b {
				font-weight: 500;
			}
			&.product-country {
				position: relative;
				white-space: nowrap;

				span {
					font-weight: inherit !important;
				}
				img {
					position: unset !important;
					margin-bottom: 5px;
				}
			}
		}
	}
	.image-additional {
		margin: 0 50px;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 3px solid $wh;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a {
			display: inline-block;
			border: $wh 2px solid;
			&.active {
				border-color: $tc;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;
		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: none;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		margin-bottom: 2px;
		@include rounded-corners(0);
		width: max-content;

		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(33px, 40px);
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;
			text-align: center;
			border: $lg 2px solid;
			&.add-up {
				border-radius: 0 4px 4px 0;
			}
			&.add-down {
				border-radius: 4px 0 0 4px;
			}
			&:hover, &:focus {
				color: $tc;
			}
		}
		svg {
			width: 11px;
			height: 11px;
			fill: none;
			stroke: $dg;
		}
		.quantity-number {
			margin: 0;
			input {
				@include size(75px, 40px);
				background: $lg;
				text-align: center;
				display: inline-block;
				box-shadow: none;
				padding: 0;
				border: none;
				border-radius: 0;
				font-size: 24px;
				font-weight: 500;
				line-height: 1;
				font-style: normal;
				color: $dg;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
			margin-left: 16px;
			font-weight: 500;
		}
		.cart {
			padding-bottom: 0;
		}
	}
	.nav-tabs {
		width: 100%;
		text-align: center;
		background: $lg;
		padding-top: 20px;
		@include flex();
		justify-content: center;
		& > li {
			display: inline-block;
			& > a {
				padding: 15px 25px;
				color: $bk;
				font-size: 25px;
				font-weight: 500;
				position: relative;
				display: inline-block;
				line-height: 1;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				white-space: nowrap;
				@media (min-width: 1150px) and (max-width: 1385px) {
					font-size: 25px;
					padding: 15px 25px;
				}
				@media (min-width: 768px) and (max-width: 1149px) {
					font-size: 21px;
					padding: 15px 10px;
				}
			}
			&.active {
				& > a {
					background: $wh;
				}
			}
		}
	}
	.tab-content {
		border: none;
		padding: 0;
		.desc-header-wrapper {
			background: $bc;
			text-align: center;
			position: relative;
			.desc-header {
				padding: 10px 0;
				color: $bk;
				font-size: 34px;
				font-weight: 900;
				position: relative;
				display: inline-block;
			}
		}
		#tab-description {
			padding: 30px 15px;
			font-size: 18px;
			list-style: none !important;
			div[class^='col-'] {
				float: none;
				width: 100%;
			}
			.flash_container {
				text-align: center;
				object {
					height: 55vw;
					max-width: 750px;
					max-height: 550px;
				}
			}
		}
		.attribs {
			padding: 30px 15px;
			.arow {
				color: $dg;
				@include flex();
				&:nth-child(odd) {
					background: $lg;
					border-radius: 5px;
				}
				div {
					padding: 5px 0;
					font-size: 18px;
				}
				.asingle {
					text-align: left;
					flex: 1;
					padding: 15px 20px;
				}
				.acol {
					flex: 0 0 50%;
					padding-left: 20px;
					&:first-child {
						text-align: left;
					}
					&:last-child {
						font-weight: 700;
					}
				}
			}
		}
		#tab-downloads {
			ul {
				margin: 0;
				li {
					@include flex();
					justify-content: center;
					padding: 15px;
					font-size: 18px;
				}
			}
		}
		#tab-videos {
			.videos {
				padding: 15px 40px;
			}
		}
		#tab-review {
			padding: 30px 0;
			.recaptcha_container label {
				display: none;
			}
			.rating {
				margin-left: 15px;
			}
		}
	}
}

.quick-view {
	.manufacturer_country {
		bottom: -2px !important;
	}
}

.storage_widget {
	padding: 15px 0;
	border-top: none !important;
	color: $dg;
	line-height: 16px;

	b {
		font-weight: 700;
	}
	table tr {
		td {
			padding-left: 0 !important;
			&:last-child {
				text-align: center !important;
			}
			&:first-child {
				display: none;
			}
			&:last-child span {
				background: #f92626 !important;
				border-radius: 5px !important;
				text-align: left !important;
				padding: 6px 10px !important;
				color: $wh !important;
				border: 1px #f92626 solid;
				font-family: $f !important;
				font-weight: 500;
				@include fade();
				&:hover {
					background: $wh !important;
					color: #f92626 !important;
				}
			}
		}
	}
	> div {
		font-size: 14px !important;
	}
}

.quick-view {
	#content {
		padding: 0 15px !important;
	}
	#sidebar-main {
		margin-bottom: 0;
	}
	.product-info {
		.product-view {
			padding: 0 0 15px 15px;
		}
		.title-product {
			padding: 15px 0;
			font-size: 30px;
			font-weight: 500;
		}
		.row {
			.list-unstyled {
				justify-content: left;
				width: auto;

				li {
					align-self: center;
				}
			}
			.pricerow {
				.price-old {
					text-decoration: line-through;
				}
				.price-new {
					font-size: 40px;
				}
			}
			.buttonrow {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				li {
					&:first-child {
						flex: 0 0 auto;
					}
				}
			}
			#button-cart {
				padding: 0;
				width: 170px;
				margin: 0;
			}
			#button-cart-credit {
				margin-left: 23px;
				cursor: pointer;
			}
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}
.for-send-error-press {
	font-style: italic;
	margin: 10px 0 15px;
}