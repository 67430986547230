.paging {
	margin: 15px;
	.pull-right {
		line-height: 36px;
	}
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;

	> li {
		display: inline;
		> a, > span {
			position: relative;
			float: left;
			margin-right: 8px;
			line-height: 30px;
			width: 30px;
			height: 30px;
			text-decoration: none;
			text-align: center;
			color: $tc;
			font-size: 18px;
			font-weight: 500;
			border-radius: 5px;
		}
		&:first-child {
			> a, > span {
				margin-left: 0;
			}
		}
	}

	> li > a, > li > span {
		&:hover, &:focus {
			color: $bk;
		}
	}

	> .active > a, > .active > span {
		&, &:hover, &:focus {
			color: $wh;
			cursor: default;
			background: $tc;
		}
	}
}