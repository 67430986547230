/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-sm-max) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-right {
		right: 0;
	}
	.row-offcanvas-left {
		left: 0;
	}
	.row-offcanvas-right .sidebar-offcanvas {
		right: -70%;
	}
	.row-offcanvas-left .sidebar-offcanvas {
		left: -70%;
	}
	.row-offcanvas-right.active {
		right: 70%;
	}
	.row-offcanvas-left.active {
		left: 70%;
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 70%;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
#offcanvasmenu {
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			background-color: $white;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 10px 20px;
				font-size: 22px;
				position: absolute;
				right: 0;
				z-index: 1;
				&.fa-plus-square {
					&:before {
						content: '\f105'
					}
				}
				&.fa-minus-square {
					&:before {
						content: '\f107'
					}
				}
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $bk;
				font-size: 16px;
				font-weight: 700;
				padding: 10px 25px 10px 15px;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		padding: 0 10px;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
		a {
			font-size: 16px;
			font-family: "Gilroy", sans-serif;
			line-height: 1.1;
			padding: 10px;
			display: block;
			color: $text-color;
			&:hover {
				color: $tc;
			}
		}
	}
	.catgrid {
		&_element {
			font-size: 16px;
			font-family: $f2;
			line-height: 1.1;
			flex: 0 1 (100% / 4);
			.text-center {
				text-align: left;
				padding-left: 10px;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $bk;
	font-size: 28px;
	line-height: 1;
	padding: 0;
	border: none;
	position: absolute;
	top: -70px;
	left: 15px;
}

.ocwall {
	background: transparentize($bk, .15);
	position: absolute;
	width: 100%;
	height: 100%;
	right: -100%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(opacity 0.2s ease-in-out);
	.menutoggle {
		position: fixed;
		top: calc(50% - 25px);
		left: calc(85vw - 25px);
		cursor: pointer;
		svg {
			@include size(50px, 50px);
			fill: none;
			stroke: transparentize($wh, .3);
			stroke-width: 3px;
			stroke-linecap: round;
		}
	}
}

.row-offcanvas.active {
	.ocwall {
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
}