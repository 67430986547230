.breadcrumb {
	> li {
		display: inline-block;
		font-size: 14px;
		line-height: 20px;
		font-weight: 300;
		color: $bk;
		+ li:before {
			content: "/\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 0 0 4px;
			color: $bk;
		}
		a {
			color: $bk;
		}
		& > span {
			font-weight: 700;
		}
	}
}
